//import listingsData from "@/data/listingCar";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import PriceDisplay from "../common/priceDisplay";
import { NumericFormat } from 'react-number-format';


const FeaturedFilterListing = ({t}) => {
    const [filter, setFilter] = useState("*");
    const [newArrivals_, setNewArrivals_] = useState([]);
    const [featured_, setFeatured_] = useState([]);
    
    const [exRate, setExRate] = useState(async () => {
		try {
			const ex_rate = await axios.get(`https://openexchangerates.org/api/latest.json?app_id=955708c18bae414d9f818c87d3364969&base=JPY&symbols=USD`)    
			
			console.log('USD : ' + ex_rate.data.rates.USD);
			setExRate(ex_rate.data.rates.USD);
			//return ex_rate.data.rates.USD;
		} catch (error) {
			console.error(error);
		}
	});

    // const filteredItems =
    //     filter === "*"
    //         ? listingsData.slice(0, 8)
    //         : listingsData.slice(0, 8).filter((item) => item.tags.includes(filter));
    
    const filteredItems =
        filter === "*"
            ? newArrivals_
            : filter === "featured" ? featured_.filter((item) => item.tag == "featured") 
                : filter === "offer" ? featured_.filter((item) => item.tag == 'offer') 
                    : filter === "clearance" ? featured_.filter((item) => item.tag == 'clearance') : newArrivals_;

    
    
    featured_.filter((item) => item.tag == "featured")?.map((item_) => {
        console.log('item_ :' + item_.title);
    })               
    
    function fetchData() {
        //Featured Listing List
        axios.get(`${process.env.REACT_APP_API_END_POINT}/api/client/listing/featured`,
            // {
            //     headers: { 'Content-Type': "*/*",  //application/json
            //                'Access-Control-Allow-Origin': "*" },
            //      //withCredentials: true
            // }
        )
        .then((res) => {
            //console.log(res.data);
            setNewArrivals_(res.data.new_arriavals);
            setFeatured_(res.data.featured);
        })
        .catch((err) => {
            console.error(err);
        })
    };

    useEffect(() => {
        fetchData();
    }, [])

    let thumbnail_ = "https://www.tc-v.com/cdn/trade/img06/cars/1689032/30564053/01.jpg";
    
    return (
        <div className="popular_listing_sliders ">
            {/* Nav tabs */}
            <div className="nav nav-tabs  justify-content-center">
                <button
                    className={filter === "*" ? "active nav-link" : "nav-link"}
                    onClick={() => setFilter("*")}
                >
                    { t('featured.new-arrivals') } 
                    {/* New Arrivals */}
                </button>

                <button
                    className={filter === "featured" ? "active nav-link" : "nav-link"}
                    onClick={() => setFilter("featured")}
                >
                    { t('featured.featured') } 
                    {/* Featured */}
                </button>
                <button
                    className={filter === "clearance" ? "active nav-link" : "nav-link"}
                    onClick={() => setFilter("clearance")}
                >
                    { t('featured.clearance') } 
                    {/* Clearance */}
                </button>
                <button
                    className={filter === "offer" ? "active nav-link" : "nav-link"}
                    onClick={() => setFilter("offer")}
                >
                    { t('featured.offers') } 
                    {/* Offers */}
                </button>
            </div>
            {/* Tab panes */}
            <div className="row">
                {filteredItems?.map((listing) => (
                    <div className="col-xs-6 col-sm-6 col-xl-3" key={listing.id}>
                        <div className="car-listing">
                            <NavLink to={`/detail/${listing.id}`}>
                            <div className="thumb">
                                {/* {listing.featured ? (
                                    <>
                                        <div className="tag">FEATURED</div>
                                    </>
                                ) : undefined}
                                {!listing.featured ? (
                                    <>
                                        <div className="tag blue">SPECIAL</div>
                                    </>
                                ) : undefined} */}

                                {listing.tag ? (
                                    <>
                                        <div className="tag">{ listing.tag }</div>
                                    </>
                                ) : undefined}
                                {!listing.tag ? (
                                    <>
                                        <div className="tag blue">New</div>
                                    </>
                                ) : undefined}

                                <img
                                    width={284}
                                    height={183}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                    priority
                                    src={(listing.tcv_images) ? JSON.parse(listing.tcv_images)[0] : thumbnail_ }
                                    alt={listing.title}
                                />
                                <div className="thmb_cntnt2">
                                    <ul className="mb0">
                                        <li className="list-inline-item">
                                            <a className="text-white" href="#">
                                                <span className="flaticon-photo-camera mr3" />{" "}
                                                {listing.photosCount}
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a className="text-white" href="#">
                                                <span className="flaticon-play-button mr3" />{" "}
                                                {listing.videosCount}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="thmb_cntnt3">
                                    <ul className="mb0">
                                        <li className="list-inline-item">
                                            <a href="#">
                                                <span className="flaticon-shuffle-arrows" />
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#">
                                                <span className="flaticon-heart" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="details">
                                <div className="wrapper">
                                    <h5 className="price">
                                        <PriceDisplay price={listing.price} rate={exRate} />
                                        {/* <img src="./assets/images/item/yen.png" alt="¥" height={13} />&nbsp; { Number(listing.price) } */}
                                    </h5>
                                    <h6 className="title">
                                        <NavLink to={`/detail/${listing.id}`}>{ listing.ref_no + ' | ' } { ((listing.title).length > 30) ? (listing.title).slice(0, 27) + '...' : listing.title }</NavLink>
                                    </h6>
                                    <div className="listign_review">
                                        <ul className="mb0">
                                            <li className="list-inline-item" style={{ letterSpacing: '1.2px' }}>
                                                { listing.m_year }<b style={{ letterSpacing: '2px', fontWeight: '900' }}>/</b>{ listing.m_month }
                                            </li>
                                            <li className="list-inline-item">
                                                &nbsp;-&nbsp;{ listing.model_code }
                                            </li>
                                            {/* {[...Array(5)].map((_, index) => (
                                                <li key={index} className="list-inline-item">
                                                    <a href="#">
                                                        <i className="fa fa-star" />
                                                    </a>
                                                </li>
                                            ))}
                                            <li className="list-inline-item">
                                                <a href="#">{listing.rating}</a>
                                            </li>
                                            <li className="list-inline-item">
                                                ({listing.reviewsCount} reviews)
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>{" "}
                                <div className="listing_footer">
                                    <ul className="mb0">
                                        <li className="list-inline-item">
                                            <span className="flaticon-road-perspective me-2" />
                                            {/* {listing.mileage} */}
											<NumericFormat style={{ fontSize: '12px' }} type="text" displayType="text"  value={listing.mileage} thousandsGroupStyle="thousand" thousandSeparator="," />
                                        </li>
                                        <li className="list-inline-item">
                                            <span className="flaticon-gas-station me-2" />
                                            {(listing.FuelType) && ((listing.FuelType?.name).length > 9) ? (listing.FuelType?.name).slice(0, 9) + '...' : listing.FuelType?.name }
                                        </li>
                                        <li className="list-inline-item">
                                            <span className="flaticon-gear me-2" />
                                            { (listing.TransmissionType)  && listing.TransmissionType?.name }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </NavLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturedFilterListing;
